<template>
  <div id="home-section">
    <div class="bg-hero" id="hero-section">
      <Navbar/>
      <MainCardSection/>
      <StatisticsSection/>
      <PayoutsSection/>
      <AboutSection/>
      <FAQSection/>
      <Footer/>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import Navbar from "./Navbar.vue";
import Footer from "./Footer.vue";
import MainCardSection from "./MainCardSection.vue";
import StatisticsSection from "./StatisticsSection.vue";
import PayoutsSection from "./PayoutsSection.vue";
import AboutSection from "./AboutSection.vue";
import FAQSection from "./FAQSection.vue";

export default Vue.extend({
  name: "Home",
  data() {
    return {
      msg: "Welcome to Your Vue.js App"
    };
  },
  components: {
    Navbar,
    Footer,
    MainCardSection,
    StatisticsSection,
    PayoutsSection,
    AboutSection,
    FAQSection
  }
});
</script>

<style lang="scss">
@import "../assets/css/main.scss";
</style>