<template>
  <div class="section mt-5" id="about-section">
    <div class="container pt-5">
      <div class="row align-items-center d-flex justify-content-between mx-0 px-0">
        <div class="col-12">
          <h2 class="text-dark w700 text-center">What is this?</h2>
        </div>
      </div>
      <div class="row align-items-center d-flex justify-content-center mt-4">
        <div class="col-11 col-lg-8 rounded-05 bg-primary shadow-primary-2 px-0 py-0 overflow-hidden">
          <img class="w-100" src="../assets/img/folding-animation.gif">
        </div>
        <div class="col-12 col-lg-9 mt-5">
          <h5 class="text-dark w400 text-center">
            With <span class="w800 text-primary">Banano Miner</span>, instead of running arbitrary calculations to "mine" a cryptocurrency, 
            your computer runs protein fold simulations through <a target="_blank" href="https://foldingathome.org" class="w800 text-primary">Folding@Home</a> to help scientists around the world fight diseases such as Alzheimers, Cancer, Parkinson's. As a result of your contribution, you get rewarded in Banano.
          </h5>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue"
import API from "../util/api.ts"

export default Vue.extend({
  name: "AboutSection",
});
</script>