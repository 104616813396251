<template>
    <div class="section mt-5" id="statistics-section">
        <div class="container pt-5">
            <div class="row align-items-center d-flex justify-content-between mx-0 px-0">
                <div class="col-12">
                    <h2 class="text-dark w700 text-center">Statistics</h2>
                </div>
            </div>
            <div class="row divider-1 mx-0 px-0 bg-primary mt-4"></div>
            <div class="row align-items-center d-flex justify-content-between mt-4 px-5">
                <div class="col-12 col-lg-6">
                    <div
                        class="row mt-4 d-flex justify-content-center justify-content-lg-start pl-lg-5"
                    >
                        <a target="_blank" href="https://foldingathome.org">
                            <img class="grow" src="../assets/img/fah-logo.svg" />
                        </a>
                    </div>
                    <div
                        class="row mt-4 d-flex justify-content-center justify-content-lg-start pl-lg-5"
                    >
                        <h4
                            class="text-dark w700 text-center text-lg-left"
                        >{{dateStr}} points contributed:</h4>
                    </div>
                    <div class="row d-flex justify-content-center justify-content-lg-start pl-lg-5">
                        <h2
                            v-if="points == null"
                            class="text-primary w800 text-center text-lg-left"
                        >Loading...</h2>

                        <h2 v-else class="text-primary w800 text-center text-lg-left grow">
                            <a
                                target="_blank"
                                href="https://stats.foldingathome.org/team/234980"
                            >{{ points }} Points</a>
                        </h2>
                    </div>
                    <div
                        class="row mt-2 d-flex justify-content-center justify-content-lg-start pl-lg-5"
                    >
                        <h4 class="text-dark w700 text-center text-lg-left">{{dateStr}} rank:</h4>
                    </div>
                    <div
                        class="row mb-4 d-flex justify-content-center justify-content-lg-start pl-lg-5"
                    >
                        <h2
                            v-if="rank == null"
                            class="text-primary w800 text-center text-lg-left"
                        >Loading...</h2>
                        <h2 v-else class="text-primary w800 text-center text-lg-left grow">
                            <a
                                target="_blank"
                                href="https://stats.foldingathome.org/team/234980"
                            >{{ rank }}</a>
                        </h2>
                    </div>
                </div>
                <div class="col-12 col-lg-6">
                    <div
                        class="row d-flex justify-content-center justify-content-lg-end mt-5 mt-lg-4 pr-lg-5"
                    >
                        <a target="_blank" href="https://banano.cc">
                            <img class="grow" src="../assets/img/banano-logo.svg" />
                        </a>
                    </div>
                    <div
                        class="row d-flex justify-content-center justify-content-lg-end mt-4 pr-lg-5"
                    >
                        <h4 class="text-dark w700 text-center text-lg-right">Distributed:</h4>
                    </div>
                    <div
                        class="row d-flex justify-content-center justify-content-lg-end mb-4 pr-lg-5"
                    >
                        <h2
                            v-if="distributed == null"
                            class="text-primary w800 text-center text-lg-right"
                        >Loading...</h2>
                        <h2 v-else class="text-primary w800 text-center text-lg-right grow">
                            <a
                                target="_blank"
                                href="https://creeper.banano.cc/explorer/account/ban_3fo1d1ng6mfqumfoojqby13nahaugqbe5n6n3trof4q8kg5amo9mribg4muo/history"
                            >{{ distributed }} BAN</a>
                        </h2>
                    </div>
                    <div
                        class="row d-flex justify-content-center justify-content-lg-end mt-4 pr-lg-5"
                    >
                        <h4 class="text-dark w700 text-center text-lg-left">Active Folders:</h4>
                    </div>
                    <div
                        class="row d-flex justify-content-center justify-content-lg-end mb-4 pr-lg-5"
                    >
                        <h2
                            v-if="active_count == null"
                            class="text-primary w800 text-center text-lg-left"
                        >Loading...</h2>
                        <h2
                            v-else
                            class="text-primary w800 text-center text-lg-left"
                        >{{ active_count }}</h2>
                    </div>
                </div>
            </div>
            <div class="d-flex row justify-content-center align-items-center px-4 my-3">
                <p style="opacity:75%" class="col-12 my-2 text-center op-50">
                    To track your own stats, you can get the
                    <span class="w800">community made</span> plugin.
                </p>
                <div class="col-12 my-1">
                    <div class="row d-flex justify-content-center">
                        <a
                            ref="noopener"
                            target="_blank"
                            href="https://chrome.google.com/webstore/detail/bananominer-watch/pdnkikfbjfhikkpopfoaihhfbhaplpfc"
                            class="btn btn-sm btn-primary shadow-primary text-white w700 rounded-05 px-3 py-2 mx-2"
                        >Get Banano Miner Watch</a>
                    </div>
                </div>
            </div>
            <div class="row divider-1 mx-0 px-0 bg-primary mt-4"></div>
        </div>
    </div>
</template>

<script>
import Vue from "vue";
import API from "../util/api.ts";

export default Vue.extend({
    name: "StatisticsSection",
    data() {
        return {
            points: null,
            rank: null,
            dateStr: null,
            distributed: null,
            active_count: null,
        };
    },
    mounted: function () {
        API.getTotalScoreMonth().then((response) => {
            if (response != null) {
                if (
                    "data" in response &&
                    response.data.score != null &&
                    response.data.rank != null &&
                    response.data.dateStr != null
                ) {
                    this.points = response.data.score.toLocaleString();
                    this.rank = response.data.rank.toLocaleString();
                    this.dateStr = response.data.dateStr
                }
            }
        });
        API.getTotalPaid().then((response) => {
            if (response != null) {
                if (
                    "data" in response &&
                    response.data.paid != null &&
                    response.data.active != null
                ) {
                    this.distributed = response.data.paid.toLocaleString();
                    this.active_count = response.data.active.toLocaleString();
                }
            }
        });
    },
});
</script>