import Axios from 'axios'

const API_LOCATION : string = location.protocol + '//' + document.domain + ':' + location.port

export default class API {
    static async registerOrRetrieve(address: string) : Promise<any> {
        try {
            let registerResponse = await Axios.post(API_LOCATION + '/register', {
                address: address
            })
            return registerResponse
        } catch (e) {
            return null;
        }
    }

    static async getRecentPayments() : Promise<any> {
        try {
            let response = await Axios(API_LOCATION + '/recent_payments')
            return response
        } catch (e) {
            return null
        }
    }

    static async getTotalScore() : Promise<any> {
        try {
            let response = await Axios(API_LOCATION + '/total_score')
            return response
        } catch (e) {
            return null
        }
    }

    static async getTotalScoreMonth() : Promise<any> {
        try {
            let response = await Axios(API_LOCATION + '/total_score_month')
            return response
        } catch (e) {
            return null
        }
    }

    static async getTotalPaid() : Promise<any> {
        try {
            let response = await Axios(API_LOCATION + '/total_paid')
            return response
        } catch (e) {
            return null
        }
    }
}