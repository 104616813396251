<template>
  <footer>
    <div class="section bg-footer mt-5" id="footer-section">
      <div class="container pt-5">
        <div class="row justify-content-center d-flex align-middle mt-0 mt-lg-4">
          <div class="col-12 col-lg-7 col-xl-6 pt-2 pb-3 mt-4">
            <div class="row">
              <a
                target="_blank"
                href="https://chat.banano.cc"
                class="col-3 col-lg mt-4 mb-3 mb-md-0 w-100"
              >
                <img class="w-100 icon grow-4" src="../assets/img/social/discord-icon.svg">
              </a>
              <a
                target="_blank"
                href="https://www.reddit.com/r/banano"
                class="col-3 col-lg mt-4 mb-3 mb-md-0 w-100"
              >
                <img class="w-100 icon grow-4" src="../assets/img/social/reddit-icon.svg">
              </a>
              <a
                target="_blank"
                href="https://twitter.com/bananocoin"
                class="col-3 col-lg mt-4 mb-3 mb-md-0 w-100"
              >
                <img class="w-100 icon grow-4" src="../assets/img/social/twitter-icon.svg">
              </a>
              <a
                target="_blank"
                href="https://t.me/banano_official"
                class="col-3 col-lg mt-4 mb-3 mb-md-0 w-100"
              >
                <img class="w-100 icon grow-4" src="../assets/img/social/telegram-icon.svg">
              </a>
              <a
                target="_blank"
                href="https://medium.com/banano"
                class="col-3 col-lg mt-4 w-100"
              >
                <img class="w-100 icon grow-4" src="../assets/img/social/medium-icon.svg">
              </a>
              <a
                target="_blank"
                href="https://www.facebook.com/bananocurrency/"
                class="col-3 col-lg mt-4 w-100"
              >
                <img class="w-100 icon grow-4" src="../assets/img/social/facebook-icon.svg">
              </a>
              <a
                target="_blank"
                href="https://www.instagram.com/bananocurrency/"
                class="col-3 col-lg mt-4 w-100"
              >
                <img class="w-100 icon grow-4" src="../assets/img/social/instagram-icon.svg">
              </a>
              <a
                target="_blank"
                href="https://www.github.com/bananocoin"
                class="col-3 col-lg mt-4 w-100"
              >
                <img class="w-100 icon grow-4" src="../assets/img/social/github-icon.svg">
              </a>
            </div>
          </div>
          <div class="col-12">
            <a target="_blank" href="https://www.banano.cc">
              <p class="text-white text-center mb-3 mt-4 grow-4">
                ©2019
                <span class="w800">BANANO</span>
              </p>
            </a>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>
<script>

import Vue from "vue";
export default Vue.extend({
  name: "Footer",
});
</script>
   