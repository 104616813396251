<template>
    <div class="row align-items-center d-flex justify-content-between m-0 p-0 border-t py-3">
            <div class="col-12 col-lg-4 px-lg-0 d-flex justify-content-start justify-content-lg-end">
              <a target="_blank" :href="`https://creeper.banano.cc/explorer/block/${hash}`"><span class="text-info font-secondary w700 break-word">{{ formatAddressDisplay(address) }}</span></a>       
            </div>
            <div class="col-12 col-lg-2 px-lg-0 d-flex justify-content-start justify-content-lg-center">
              <span class="text-primary w700">{{ amount }} BAN</span>
            </div>
            <div class="col-12 col-lg-1 px-lg-0 d-flex justify-content-start justify-content-lg-center">
              <span class="text-info w700">{{ status }}</span>
            </div>
            <div class="col-12 col-lg-3 px-lg-0 d-flex justify-content-start">
              <span class="text-info w700">{{ formatDate(date) }}</span>
            </div>
    </div>
</template>

<script>
import Vue from "vue"
import Util from "../util/util.ts"

export default Vue.extend({
  name: "PayoutItem",
  props: {
      address: String,
      amount: Number,
      status: String,
      date: String,
      hash: String,
  },
  methods: {
      formatDate(date) {
          return Util.formatDateStr(date)
      },
      formatAddressDisplay(address) {
          return `${address.substring(0, 14)}...${address.substring(54)}` 
      },
  }
});
</script>
