<template>
  <div class="section mt-5" id="payouts-section">
    <div class="container pt-5">
      <div class="row align-items-center d-flex justify-content-center mx-0 px-0 mb-4">
        <div class="col-12">
          <h2 class="text-dark w700 text-center">Recent Payouts</h2>
        </div>
      </div>
      <div class="row align-items-center d-flex justify-content-center m-0 p-0">
        <div class="col-12 col-lg-11 col-xl-10">
          <payout-item
            v-for="item in history"
            :key="item.hash"
            :address="item.address"
            :amount="item.amount"
            :status="item.status"
            :date="item.created"
            :hash="item.hash"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import PayoutItem from "./PayoutItem.vue";
import API from "../util/api.ts";

export default Vue.extend({
  name: "PayoutsSection",
  data() {
    return {
      history: []
    };
  },
  components: {
    PayoutItem
  },
  mounted: function() {
    API.getRecentPayments().then(response => {
      if (response != null) {
        this.history = response.data;
      }
    });
  }
});
</script>