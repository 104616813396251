<template>
  <div class="section mt-4 mt-lg-5" id="main-card-section">
    <div class="container px-0">
      <div
        class="main-card shadow-dark-2 rounded-05 bg-white overflow-hidden mx-3 mx-md-0"
      >
        <div
          class="row align-items-center d-flex justify-content-between mx-0 px-0"
        >
          <div class="col-12 col-lg-5 bg-card py-4 py-lg-5">
            <div class="py-lg-3">
              <h2
                class="text-white w700 text-center text-lg-left textshadow-dark mx-3 mx-lg-5 mt-2 mb-5 my-lg-5"
              >
                Earn BANANO by contributing to medical research.
              </h2>
            </div>
          </div>
          <div class="col-12 col-lg-6">
            <div class="row d-flex justify-content-center mx-3 mt-4">
              <h4 class="text-dark font-weight-bold text-center mx-2 mx-lg-1">
                Enter your BANANO address below to start.
              </h4>
              <p class="text-dark text-center w400">
                <small
                  >Folding@Home is not affiliated with Banano.<br />They won't
                  be able to answer your inquiries.</small
                >
              </p>
            </div>
            <div
              class="form-group row d-flex justify-content-center mx-0 mb-0 mx-lg-3"
            >
              <input
                class="form-control font-weight-bold form-control form-control-lg block border-2 border-primary p-4 rounded-05 mx-0 textfield-dark"
                :class="[isValidAddress ? 'text-success' : '']"
                id="exampleInputEmail1"
                aria-describedby="bananoAddress"
                placeholder="ban_3fo1d1ng6..."
                ref="banAddress"
                @input="onAddressChanged"
              />
              <div
                class="text-danger mt-1"
                :style="{
                  'margin-bottom': '-10px',
                  visibility: showAddressInvalid ? 'visible' : 'hidden',
                }"
              >
                Address is Invalid
              </div>
            </div>
            <div class="row d-flex justify-content-center mt-3 mb-4 mb-lg-0">
              <button
                class="btn btn-primary shadow-primary text-white w700 btn-lg rounded-05 px-5"
                @click="startMining"
              >
                Start Now!
              </button>
            </div>
            <div class="row d-flex justify-content-center">
              <p class="text-dark text-center mt-0 mt-lg-3 w400">
                <small>
                  Need a BANANO address? Get
                  <a
                    class="w800"
                    href="https://kalium.banano.cc/"
                    target="_blank"
                    >Kalium</a
                  >
                  or
                  <a
                    class="w800"
                    href="https://vault.banano.cc/"
                    target="_blank"
                    >Bananovault</a
                  >.
                </small>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <MiningModal
      :show="showModal"
      :shouldHide="hideModal"
      :address="addressValue"
    />
  </div>
</template>

<script>
import Vue from "vue";
import MiningModal from "./MiningModal.vue";
import Util from "../util/util.ts";

export default Vue.extend({
  name: "MainCardSection",
  data() {
    return {
      showModal: false,
      addressValue: "",
      isValidAddress: false,
      showAddressInvalid: false,
    };
  },
  methods: {
    startMining(event) {
      if (this.isValidAddress) {
        this.showModal = true;
      } else {
        this.showAddressInvalid = true;
      }
    },
    hideModal() {
      this.showModal = false;
    },
    onAddressChanged(event) {
      this.showAddressInvalid = false;
      this.addressValue = event.target.value;
      if (this.addressValue.length < 64) {
        this.isValidAddress = false;
      } else {
        let parsed = Util.validateAddress(this.addressValue);
        if (parsed !== false) {
          this.isValidAddress = true;
          this.addressValue = parsed;
        } else {
          this.isValidAddress = false;
        }
      }
      event.target.value = this.addressValue;
    },
  },
  components: {
    MiningModal,
  },
});
</script>
