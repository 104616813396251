<template>
  <div class="section mt-5 pb-5" id="about-section">
    <div class="container pt-5 pb-4">
      <div class="row align-items-center d-flex justify-content-between mx-0 px-0">
        <div class="col-12">
          <h2 class="text-dark w700 text-center">FAQ</h2>
        </div>
      </div>
      <div class="row align-items-center d-flex justify-content-center mt-4">
        <div class="col-12 col-lg-9">
          <VueFaqAccordion
          :items="myItems"
          activeColor='#FD8105'
          borderColor='rgb(242, 242, 244)'
          fontColor='#47494F'
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import VueFaqAccordion from "vue-faq-accordion";

export default Vue.extend({
  name: "FAQSection",
  components: {
    VueFaqAccordion
  },
  data() {
    return {
      myItems: [
        {
          title: "What is Point/Banano ratio?",
          value:
            "You should contribute to folding@home because you support the work they do, not because you may get paid for it. BananoMiner is not intended to be a source of income, you will generally get compensated for the work you do with some BANANO - however the amounts are subject to change and not guaranteed. You can see what other people are getting on <a class='w700' href=\"https://chat.banano.cc\" target=\"_blank\">Discord</a>."
        },
        {
          title: "Can I use my GPU?",
          value:
            'Yes, the <a class="w700" href="https://foldingathome.org/start-folding/" target="_blank">folding@home client</a> should set up GPU folding automatically for you.'
        },
        {
          title: "How do I start folding?",
          value:
            'Go to <a target="_blank" href="https://bananominer.com" class="w700">bananominer.com</a> first and input your address, and you will get a User ID and Team ID. You <span class="w700">must</span> input the User ID and Team ID into the <a class="w700" href="https://foldingathome.org/start-folding/" target="_blank">folding@home client</a> in order to get rewarded.'
        },
        {
          title:
            "Do I need to complete a full Work Unit before points are updated?",
          value:
            "Yes, but you can start or pause the process any time you want and continue later on to finish a full Work Unit."
        },
        {
          title: "Does F@H work on mobile devices?",
          value:
            'No. You may fold on Windows, Mac, or Linux systems using the desktop client: <a class="w700" target="_blank" href="https://foldingathome.org/start-folding/">Here</a>.'
        },
        {
          title: "Does F@H work on Chrome/Brave/Firefox/Safari or other browsers?",
          value:
            'No. Folding@home requires a desktop client to be installed, you may install it following the instructions: <a class="w700" target="_blank" href="https://foldingathome.org/start-folding/">Here</a>.'
        },
        {
          title: "How can I check my Points?",
          value:
            "Your current number of points are displayed in the actual folding@home client itself."
        },
        {
          title: "How do I earn more Points?",
          value:
            'Aside from having better hardware, you can request a <a class="w700" href="https://foldingathome.org/support/faq/points/passkey/" target="_blank">passkey</a>. Having a passkey will grant you some bonus points.'
        }        
      ]
    };
  }
});
</script>
