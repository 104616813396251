<template>
  <div class="container-fluid z-top">
    <nav>
      <div class="container">
        <div class="row d-flex justify-content-between py-3">
          <a href="./">
            <img class="grow logo" src="../assets/img/bananominer-logo.svg">
          </a>
          <button v-scroll-to="{element: '#about-section', duration: 400, easing: 'ease'}"
            class="btn btn-white bg-white shadow-dark text-primary w800 btn-lg rounded-05 px-3 px-md-5 d-none d-lg-block">
          What is this?</button>
          <button v-scroll-to="{element: '#about-section', duration: 400, easing: 'ease'}"
            class="btn btn-white bg-white shadow-dark text-primary w800 btn-lg rounded-05 px-3 px-md-5 d-lg-none">
          What?</button>
        </div>
      </div>
    </nav>
    <AboutModal :show="showModal" :shouldHide="hideModal" />
  </div>
</template>

<script>
import Vue from "vue";
import AboutModal from "./AboutModal.vue"
var VueScrollTo = require('vue-scrollto');

Vue.use(VueScrollTo)

export default Vue.extend({
  name: "Navbar",
  components: {
    AboutModal
  },
  data() {
    return {
      showModal: false
    }
  },
  methods: {
    hideModal() {
      this.showModal = false
    }
  }
});
</script>