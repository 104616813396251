<template>
  <vodal id="about-modal-section" :show="show" animation="zoom" @hide="shouldHide()" :customStyles="{ height: 'auto', bottom: 'auto', top: '50%', transform: 'translateY(-50%)'}">
    <div class="section mt-4 mt-lg-5">
        <p>
            <a href="https://banano.cc" target="_blank">BANANO</a> is a feeless, instant cryptocurrency. While BANANO cannot be mined, it is being actively distributed through various games, giveaways, and faucets.
        </p>
        <p>
            You can earn banano by contributing to <a href="https://foldingathome.org/">folding@home</a> by registering your ban_ address below. Simply register below and start folding.
        </p>
    </div>
  </vodal>
</template>

<script>
import Vue from "vue"
import Vodal from 'vodal'

export default Vue.extend({
  name: "AboutModal",
  props: {
    show: Boolean,
    shouldHide: Function
  },
  components: {
    Vodal
  },
});
</script>

<style>
@import "~vodal/common.css";
@import "~vodal/zoom.css";
</style>