<template>
  <vodal id="mining-modal-section" :show="show" animation="zoom" @hide="shouldHide()" :customStyles="{ height: 'auto', bottom: 'auto', top: '50%', transform: 'translateY(-50%)'}" :closeButton="false">
    <div class="section">
        <div v-if="loading">Loading...</div>
        <div v-else-if="hasErrors">
          <div>{{ errorText }}</div>
        </div>
        <div v-else>
          <div class="row">
            <div class="col-6">
              <h4 class="text-dark text-center w700">Name</h4>
              <pre><code>{{ user_id }}</code></pre>
            </div>
            <div class="col-6">
              <h4 class="text-dark text-center w700">Team Number</h4>
              <pre><code>{{ team_id }}</code></pre>
            </div>
          </div>
          <div class="row">
            <p class="text-dark pl-3 pr-3">
              You will get rewarded for all Folding@Home contributions as long as you use the "Name" and "Team Number" exactly as it is shown above. You <span class="font-weight-bold">must</span> enter your "Name" and "Team Number" into the client exactly as it is shown above, otherwise you will not be rewarded for your contributions.
            </p>
            <p class="text-dark pl-3 pr-3">
              Simply setup the <a href="https://foldingathome.org/start-folding/">Folding@Home client</a> and enter your "Name" and "Team Number" and start folding!
            </p>
          </div>
          <div class="row">
            <div class="col-12">
              <a href="https://foldingathome.org/start-folding/" 
                  class="btn btn-primary shadow-primary text-white w700 btn-lg rounded-05 px-4 px-md-5 w-100"
                  target="_blank">
                  Start Folding!
              </a>
            </div>
          </div>
        </div>
    </div>
  </vodal>
</template>

<script>
import Vue from "vue"
import Vodal from 'vodal'
import API from '../util/api.ts'

export default Vue.extend({
  name: "MiningModal",
  data() {
    return {
      loading: true,
      hasErrors: false,
      errorText: '',
      user_id: '',
      team_id: ''
    }
  },
  props: {
    show: Boolean,
    shouldHide: Function,
    address: String
  },
  components: {
    Vodal
  },
  methods: {
    registerOrRetrieveUser() {
      API.registerOrRetrieve(this.address).then((response) => {
        this.loading = false
        if (response == null || !('data' in response) ) {
          this.hasErrors = true
          this.errorText = 'A server error occured. Try again later.'
        } else {
          if ('error' in response.data) {
            this.hasErrors = true
            this.errorText = response.data.error
          } else {
            this.hasErrors = false
            this.user_id = response.data.user_id
            this.team_id = response.data.team_id
          }
        }
      })
    }
  },
  watch: {
    show: function(newVal, oldVal) {
      if (newVal) {
        this.loading = true
        this.user_id = ''
        this.team_id = ''
        this.registerOrRetrieveUser()
      } else {
        // Closing
        this.hasErrors = false
        this.errorText = ''
      }
    }
  }
});
</script>

<style>
@import "~vodal/common.css";
@import "~vodal/zoom.css";
</style>